<template>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-button
          style="float: right"
          type="primary"
          @click="dialogFormVisible = true"
          size="medium"
          v-debounce
          >添加角色</el-button
        >
      </div>
      <el-dialog title="添加角色" :visible.sync="dialogFormVisible">
        <el-form :model="form">
          <el-form-item label="角色名" :label-width="formLabelWidth">
            <el-input v-model="form.name" autocomplete="off" placeholder="请输入角色名"></el-input>
          </el-form-item>
          <el-form-item label="角色ID" :label-width="formLabelWidth">
            <el-input v-model="form.rolename" autocomplete="off" placeholder="请输入角色ID"></el-input>
          </el-form-item>
          <el-form-item label="角色描述" :label-width="formLabelWidth">
            <el-input v-model="form.des" autocomplete="off" placeholder="请输入角色描述"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addroleid()"
            >添 加</el-button
          >
        </div>
      </el-dialog>
      <!--修改-->
      <el-dialog title="修改角色" :visible.sync="editdialogFormVisible">
        <el-form :model="editform">
            <el-form-item label="角色名" :label-width="formLabelWidth">
            <el-input v-model="editform.name" autocomplete="off" placeholder="请输入角色名"></el-input>
          </el-form-item>
          <el-form-item label="角色ID" :label-width="formLabelWidth">
            <el-input v-model="editform.rolename" autocomplete="off" placeholder="请输入角色ID"></el-input>
          </el-form-item>
          <el-form-item label="角色描述" :label-width="formLabelWidth">
            <el-input v-model="editform.des" autocomplete="off" placeholder="请输入角色描述"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="editdialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="roleedit(editform)"
            >确 定</el-button
          >
        </div>
      </el-dialog>
      <!--修改-->
      <el-table
        :data="items"
        style="width: 100%; margin-bottom: 20px"
        row-key="_id"
        border
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="name" label="角色名" > </el-table-column>
        <el-table-column prop="rolename" label="角色ID"></el-table-column>
        <el-table-column prop="des" label="角色描述"></el-table-column>
        <el-table-column prop="date" label="创建时间"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleedit(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="handlePageChange"
        @size-change="handlePageSizeChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 20, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
  </template>
  <script>
  
  export default {
    data() {
      return {
        editdialogFormVisible: false,
        dialogFormVisible: false,
        formLabelWidth: "120px",
        currentPage: 1, // 当前页码
        pageSize: 5, // 每页数据量
        total: 0, // 总数据量
        items: [],
        form: {
          name: "",
          rolename:"",
          des: "",
        },
        editform: {
          _id:"",
          name: "",
          rolename:"",
          des: "",
        },
        checkoptions: [],
        tableData: [],
      };
    },
    methods: {
      roleedit(data){
        this.$api.roleids.roleedit(data).then(res =>{
          this.$message({
            message:res.data.msg,
            type: 'success'
          });
          this.loadItems()
          this.editdialogFormVisible = false;
        })
      },
      handleedit(data){
        this.editdialogFormVisible = true;
        const {_id,rolename,name,des} = data
        this.editform._id = _id
        this.editform.name = name
        this.editform.rolename = rolename
        this.editform.des= des
      },
      handleDelete(data){
        this.$api.roleids.deleterole(data).then(res =>{
          this.$message({
            message:res.data.msg,
            type: 'success'
          });
          this.loadItems()
        })
      },
      addroleid() {
        const data = {
            name: this.form.name,
            rolename:this.form.rolename,
            des: this.form.des,
        }
        //console.log(this.form.parent)
        this.$api.roleids.createroleid(data).then(res =>{
          this.$message({
            message:res.data.msg,
            type: 'success'
          });
          this.dialogFormVisible = false
          this.loadItems();
          this.form = {}
        })
      },
      handlePageChange(page) {
        this.currentPage = page;
        this.loadItems();
      },
      handlePageSizeChange(pageSize) {
        this.pageSize = pageSize;
        this.loadItems();
      },
      loadItems(){
        const data = {
          currentPage: this.currentPage,
          pageSize:this.pageSize
        }
        this.$api.roleids.roleidlist(data).then(res =>{
          this.currentPage = res.data.pagination.currentPage;
          this.pageSize = res.data.pagination.pageSize;
          this.total = res.data.pagination.total;
          this.items = res.data.data;
        })
      },
    },
    created(){
      this.loadItems()
    }
  };
  </script>
  <style>
  .text {
    font-size: 14px;
  }
  
  .item {
    margin-bottom: 18px;
  }
  
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both;
  }
  
  .box-card {
    width: 100%;
  }
  </style>  